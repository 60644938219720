<template>
  <div class="container">
    <!-- 按钮列表 -->
    <div
      class="btnList1"
      ref="btnList"
    >
      <el-button
        class="itemBtn btnColor"
        @click="itemClick(1)"
      >新建</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="itemClick(2)"
      >修改</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="itemClick(3)"
      >查看</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="delBtns"
      >删除</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="EnableBut(0)"
      >禁用</el-button>
      <el-button
        class="itemBtn btnColor"
        @click="EnableBut(1)"
      >启用</el-button>

      <el-button
        class="itemBtn btnColor"
        @click="myModel"
      >列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件 -->
    <el-form :model="queryData">
      <div
        ref="queryCriteria"
        class="HeadInputbox"
        :class="{ HeadInputbox1: judge, active: isActive }"
      >
        <div class="item_right">
          <!-- <div class="singleinp wtlTimeBox">
            <div class="singleinpleft wtlTimeLabel">创建时间:</div>
            <div class="wtl_timeStyle">
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.startCreateDate"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
              <div>至</div>
              <el-date-picker
                class="input"
                value-format="yyyy-MM-dd"
                v-model="queryData.endCreateDate"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </div>
          </div> -->
          <div class="singleinp">
            <div class="singleinpleft">行事历名称:</div>
            <div class="singleinpright">
              <el-input
                v-model="queryData.calendarName"
                clearable
                placeholder="请输入"
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">所属仓库:</div>
            <div class="singleinpright">
              <el-select
                v-model="queryData.storageId"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in storeArr"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">运输方式:</div>
            <div class="singleinpright">
              <el-select
                v-model="queryData.channelTransportId"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in transportList"
                  :label="item.transportName"
                  :key="index"
                  :value="String(item.channelTransportId)"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="item_left">
          <div class="singleinpbtn">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
            >查询</el-button>
            <el-tooltip
              content="查询项恢复初始状态"
              placement="top"
            >
              <div
                class="condition"
                @click="queryBtn_cz"
              >清除</div>
            </el-tooltip>
            <el-tooltip
              content="查询更多条件"
              placement="top"
            >
              <div
                class="condition"
                @click="more"
              >
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div
      class="tableBox"
      ref="tableWrapper"
      :class="{ tableBox: TableStyle, tableBox1: fullscreen }"
    >
      <el-table
        :height="tableHeigth"
        ref="mytable"
        :data="tableData"
        show-summary
        :summary-method="getSummaries"
        :row-class-name="tableRowClassName"
        highlight-current-row
        :header-cell-style="{ background: '#f0f0f0' }"
        border
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          fixed="left"
          label="序号"
          type="index"
          width="60"
        >
        </el-table-column>
        <el-table-column
          fixed="left"
          type="selection"
          width="60"
        >
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            :label="item.name"
            :prop="item.field"
            sortable
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>

        <el-table-column
          align="center"
          width="120"
          label="操作"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="clickTimeMaintenance(scope.row)"
            >行事历时间维护</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- //排序 -->
    <div>
      <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>
    </div>
    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- 自定义排序表头 -->
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button
              size="small"
              type="primary"
            >点击上传</el-button>
            <div
              slot="tip"
              class="el-upload__tip"
            >上传模板</div>
          </el-upload>
        </div>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>

    <!-- 弹窗 -->
    <myDialog
      :diaWidth="diaWidth"
      :dialogTitle="PopUpTitle"
      :isShowDialog="menushowBOX"
      @handleCloseDia="handleCloseDia"
      @diaCancelBtn="diaCancelBtn"
      @diaConfirmBtn="diaConfirmBtn('dialogFormDataRef')"
      :dialogFooter="dialogType != 3"
    >
      <div class="glo_dialogForm">
        <el-form
          class="diaForm"
          v-if="menushowBOX"
          ref="dialogFormDataRef"
          :model="dialogFormData"
          label-width="100px"
        >
          <el-form-item
            class="dialogFormItems"
            label="行事历名称:"
          >
            <el-input
              placeholder="请输入"
              clearable
              v-model="dialogFormData.calendarName"
              :disabled="dialogType == 3"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            label="所属仓库:"
          >
            <el-select
              v-model="dialogFormData.storageId"
              clearable
              filterable
              placeholder="请选择"
              :disabled="dialogType == 3"
            >
              <el-option
                v-for="item in storeArr"
                :key="item.id"
                :label="item.storageName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="dialogFormItems"
            label="运输方式:"
          >
            <el-select
              v-model="dialogFormData.channelTransportId"
              clearable
              filterable
              placeholder="请选择"
              :disabled="dialogType == 3"
            >
              <el-option
                v-for="(item, index) in transportList"
                :label="item.transportName"
                :key="index"
                :value="item.channelTransportId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            class="dislogFormTextarea"
            label="备注:"
          >
            <el-input
              type="textarea"
              rows="2"
              v-model="dialogFormData.remarks"
              :disabled="dialogType == 3"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dislogFormTextarea"
            label="节点名称:"
          >
            <div
              class="nodlist"
              v-for="(item, index) in dialogFormData.actionNodes"
            >
              <el-input
                placeholder="请输入"
                clearable
                v-model="item.nodeName"
                :disabled="dialogType == 3"
                style="width: 150px;"
              ></el-input>
              <el-input
                placeholder="请输入"
                clearable
                v-model="item.showName"
                :disabled="dialogType == 3"
                style="width: 150px;"
              ></el-input>
              <span
                v-if="dialogType != 3"
                @click="delNode(index)"
                style="color: #f00;"
              >删除</span>
              <span
                style="color: #409EFF;"
                v-if="
                  dialogType != 3 &&
                    dialogFormData.actionNodes.length - 1 == index
                "
                @click="addNode"
              >添加</span>
            </div>
          </el-form-item>
          <el-form-item
            class="dislogFormTextarea"
            label="文字内容:"
          >
            <quill-editor
              class="ql-editor"
              v-model="dialogFormData.articleContent"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange($event)"
              :disabled="dialogType == 3"
            >
            </quill-editor>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>
  </div>
</template>

<script>
import { apiList, Api } from '@/assets/js/api'
import tools from '@/assets/js/tool'
import draggable from 'vuedraggable' //拖拽组件
import sorttable from '@/components/sortTemplate.vue'
import paging from '@/components/pagings.vue'
import screenfull from 'screenfull'
import myDialog from '@/components/Dialog.vue'

export default {
  inject: ['reload'],
  components: {
    // mytables,
    sorttable,
    draggable,
    paging,
    myDialog,
  },
  data() {
    return {
      PopUpTitle: '', //弹窗标题
      menushowBOX: false, //菜单弹窗
      dialogType: '',
      diaWidth: '60%',
      dialogFormData: {
        actionNodes: [
          {
            nodeName: '',
            showName: '',
          },
        ],
        articleContent: '',
        calendarName: '',
        channelTransportId: '',
        remarks: '',
        storageId: '',
      },
      actionNodes: [],
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
            ['blockquote', 'code-block'], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
            [{ script: 'sub' }, { script: 'super' }], // 上标/下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            [{ size: ['12px', false, '16px', '18px', '20px', '30px'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [
              {
                font: [
                  false,
                  'SimSun',
                  'SimHei',
                  'Microsoft-YaHei',
                  'KaiTi',
                  'FangSong',
                  'Arial',
                ],
              },
            ], // 字体种类
            [{ align: [] }], // 对齐方式
            ['clean'], // 清除文本格式
            ['link', 'image', 'video'], // 链接、图片、视频
          ],
        },
        placeholder: '请输入正文',
      },
      transportList: [], //  运输方式下拉菜单
      storeArr: [], //  仓库数组
      queryData: {
        // startCreateDate: '',
        // endCreateDate: '',
        calendarName: '',
        storageId: '',
        channelTransportId: '',
      },
      isActive: false, //输入框绑定动态class
      judge: true, //输入框绑定动态class
      MoreConditions: '更多条件', //输入框绑定动态class
      fullscreen: false,
      TableStyle: true,
      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: '50vh',
      tabelHeadeTitle: [
        {
          name: '行事历名称',
          field: 'calendarName',
          width: '120',
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '所属仓库',
          field: 'storageName',
          width: '120',
          sort: 4,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: '运输方式',
          field: 'channelTransportName',
          width: '180',
          sort: 7,
          isShow: true,
          isTotal: false,
        },
        {
          name: '状态',
          field: 'isEnableShow',
          width: '180',
          sort: 8,
          isShow: true,
          isTotal: false,
        },
        {
          name: '备注',
          field: 'remarks',
          width: '180',
          sort: 8,
          isShow: true,
          isTotal: false,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: '', //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: '', //上传路径
      sortName: '模板名', //排序名
      pageGroupName: 'CalendarList', //页面标识
      selectTableData: [], //选中的数据
      length: null,
      //===============
    }
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1
      }
      this.tabelHeadeTitle = arr
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      )
    }
    this.getChannelAndTransportList()
    this.myGetStorageList()
    this.getGroupFindFieldSort() //获取用户自定义表头
    // this.myTableHeard = this.tabelHeadeTitle;

    //this.queryData.endCreateDate = tools.getCurrentDate() //今天的时间;
    //this.queryData.startCreateDate = tools.getFlexDate(-10) //10天前的时间
  },
  mounted() {
    this.getData()
  },
  watch: {},
  methods: {
    addNode() {
      this.dialogFormData.actionNodes.push({
        nodeName: '',
        showName: '',
      })
    },
    delNode(index) {
      if (this.dialogFormData.actionNodes.length <= 1) {
        this.$message.warning('请至少保留一条数据')
        return false
      }
      this.dialogFormData.actionNodes.splice(index, 1)
    },
    clickTimeMaintenance(row) {
      this.$router.push({
        path: '/systemManagement/Calendar/TimeMaintenance',
        query: {
          calendarActionId: row.calendarActionId,
        },
      })
    },
    // 内容改变事件
    onEditorChange({ quill, html, text }) {
      this.dialogFormData.articleContent = html
    },
    // 删除
    delBtns() {
      if (this.selectTableData.length < 1) {
        this.$message.warning('请至少选择一条数据进行删除')
        return false
      }
      let ids = []
      this.selectTableData.forEach((item) => {
        ids.push(item.calendarActionId)
      })
      this.$confirm('是否删除选中的数据?', '提示:')
        .then(() => {
          let param = {
            calendarActionIds: ids,
          }
          param.sign = tools.getSign(param)
          Api.delCalentdarAction(param).then((res) => {
            this.$message.success(res.data.message || '删除成功')
            this.getData()
          })
        })
        .catch(() => {})
    },
    //启用 == 1  禁用==0
    EnableBut(enableType) {
      let _this = this
      let selectTableData = this.selectTableData || []
      if (selectTableData.length < 1) {
        this.$message.warning('请至少勾选一条数据')
        return
      }
      let ids = []
      selectTableData.forEach((item, index) => {
        ids.push(item.calendarActionId)
      })
      let strs = '禁用'
      if (enableType == 1) {
        strs = '启用'
      }
      this.$confirm('是否' + strs + '当前选择的数据?', '提示:')
        .then(() => {
          let param = {
            calendarActionIds: ids,
          }
          let sign = tools.getSign(param)
          param.sign = sign
          if (enableType == 1) {
            Api.startCalentdarAction(param).then((res) => {
              if ((res.data.status = '"success"')) {
                _this.$message.success(res.data.message || '操作成功')
                _this.getData()
              }
            })
          } else {
            Api.stopCalentdarAction(param).then((res) => {
              if ((res.data.status = '"success"')) {
                _this.$message.success(res.data.message || '操作成功')
                _this.getData()
              }
            })
          }
        })
        .catch(() => {})
    },
    itemClick(type) {
      this.dialogType = type
      if (type == 1) {
        this.PopUpTitle = '新建'
      } else if (type == 2) {
        this.PopUpTitle = '修改'
      } else if (type == 3) {
        this.PopUpTitle = '查看'
      }
      if (type == 2 || type == 3) {
        let selectTableData = this.selectTableData || []
        if (selectTableData.length != 1) {
          this.$message.warning('请勾选一条数据')
          return
        }
        let calendarActionId = selectTableData[0].calendarActionId
        Api.getCalentdarActionDetail({
          calendarActionId,
        }).then((res) => {
          if (res.data.status === 'success') {
            const {
              actionNodes = [
                {
                  nodeName: '',
                  showName: '',
                },
              ],
              articleContent = '',
              calendarName = '',
              channelTransportId = '',
              remarks = '',
              storageId = '',
              calendarActionId = '',
            } = res.data.result
            this.actionNodes = JSON.parse(JSON.stringify(actionNodes))
            actionNodes.forEach((n) => {
              n.operationType = 'UNCHANGED'
            })
            this.dialogFormData = {
              actionNodes,
              articleContent,
              calendarName,
              channelTransportId,
              remarks,
              storageId,
              calendarActionId,
            }
          } else {
            this.$message.error(`${res.data.message}--详情`)
          }
        })
      }
      this.menushowBOX = true
    },
    /***
     * ====== 弹窗组件方法 ======
     */
    //  弹窗关闭事件(左上角的x和关闭按钮都算)
    handleCloseDia() {
      this.menushowBOX = false
      this.dialogFormData = {
        actionNodes: [
          {
            nodeName: '',
            showName: '',
          },
        ],
        articleContent: '',
        calendarName: '',
        channelTransportId: '',
        remarks: '',
        storageId: '',
      }
    },
    //  弹窗确认保存事件
    diaConfirmBtn(formName) {
      if (this.dialogType == 1) {
        // 新建
        this.dialogFormData.sign = tools.getSign(this.dialogFormData)
        this.dialogFormData.actionNodes.forEach((n, i) => {
          n.sort = i
        })
        Api.addCalentdarAction(this.dialogFormData).then((res) => {
          this.$message.success(res.data.message || '新建成功')
          this.menushowBOX = false
          tools.resetForm(this, formName)
          this.getData()
        })
      } else if (this.dialogType == 2) {
        // 修改
        this.dialogFormData.actionNodes.forEach((n, i) => {
          n.sort = i
          if (
            this.actionNodes.findIndex((item) => {
              return n.nodeId == item.nodeId
            }) == -1
          ) {
            n.operationType = 'ADD'
          } else {
            n.operationType = 'UPDATE'
          }
        })
        let delarrs = []
        this.actionNodes.forEach((n, i) => {
          if (
            this.dialogFormData.actionNodes.findIndex((item) => {
              return n.nodeId == item.nodeId
            }) == -1
          ) {
            n.operationType = 'DEL'
            delarrs.push(n)
          }
        })
        this.dialogFormData.actionNodes = [
          ...this.dialogFormData.actionNodes,
          ...delarrs,
        ]
        this.dialogFormData.sign = tools.getSign(this.dialogFormData)
        Api.updateCalentdarAction(this.dialogFormData).then((res) => {
          this.$message.success(res.data.message || '修改成功')
          this.menushowBOX = false
          tools.resetForm(this, formName)
          this.getData()
        })
      }
    },
    //  关闭弹窗按钮事件(关闭按钮)
    diaCancelBtn() {
      this.menushowBOX = false
    },

    /***
     * ====== 弹窗组件方法 ======
     */
    myGetStorageList() {
      //  获取仓库下拉列表
      Api.getStorageList().then((res) => {
        if (res.data.status === 'success') {
          this.storeArr = res.data.result || []
        } else {
          this.$message.error(`${res.data.message}--仓库下拉列表`)
        }
      })
    },
    getChannelAndTransportList() {
      //  获取渠道与运输方式下拉菜单
      Api.channelAndTransportList().then((res) => {
        console.log(123)
        if (res.data.status === 'success') {
          let obj = {}
          this.transportList = res.data.result.reduce((cur, next) => {
            //  去重
            obj[next.channelTransportId]
              ? ''
              : (obj[next.channelTransportId] = true && cur.push(next))
            return cur
          }, [])
        } else {
          this.$message.error(`${res.data.message}--渠道与运输方式下拉菜单`)
        }
      })
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return 'warning-row'
      }
    },

    handleCommandTabs(m) {
      if (m == 'a') {
        this.myModel()
      } else if (m == 'b') {
        this.exportBtn()
      } else if (m == 'c') {
        this.exportTemplateDownload()
      } else if (m == 'd') {
        this.upLoadBtn()
      } else {
        this.screen()
      }
    },
    //全屏模式
    screen() {
      // if (screenfull.isEnabled) {
      //     screenfull.toggle(this.$refs.tableWrapper);
      // }
    },
    // 阻止F11默认事件
    keydown(event) {
      if (event.keyCode === 122) {
        event.preventDefault()
        event.returnValue = false
      }
    },

    /**
     * 响应屏幕变化事件，给isFullscreen赋值，标识是否全屏
     * IE中bug: 调试器打开，且与页面不分离时 -- 无效
     * **/
    onresize(event) {
      // 利用屏幕分辨率和window对象的内高度来判断兼容IE
      let winFlag = window.innerHeight === window.screen.height
      // 利用window全屏标识来判断 -- IE无效
      let isFull = window.fullScreen || document.webkitIsFullScreen

      if (isFull === undefined) {
        this.isFullscreen = winFlag
      } else {
        this.isFullscreen = winFlag || isFull
      }
      //console.log('winFlag || isFull', winFlag, isFull)
      if (winFlag == true && isFull == true) {
        this.fullscreen = true
        this.TableStyle = false

        //console.log(this.tableHeigth)
      } else {
        this.fullscreen = false
        this.TableStyle = true
      }
    },

    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive
      this.judge = !this.judge
      this.fetTableHeight()
      if (this.judge == false) {
        this.MoreConditions = '更多条件'
      } else {
        this.MoreConditions = '收起条件'
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e
      this.length = e.length
    },

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      const file = param.file,
        fileName = file.name

      // 根据后台需求数据格式
      const form = new FormData()
      // 文件对象
      form.append('file', file)
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append('fileName', fileName)
      let signArr = [
        {
          key: 'fileName',
          val: fileName,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      form.append('sign', sign)
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == 'success') {
            let fileName = res.data.result.fileName

            let signArr = [
              {
                key: 'groupName',
                val: this.pageGroupName,
              },
              {
                key: 'templateName',
                val: this.sortName,
              },
              {
                key: 'fileName',
                val: fileName,
              },
            ]
            let sign_ = tools.getMD5Sign(signArr)
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || '更新成功')
              this.show_upload = false
            })
          }
        })
        .catch((err) => {})
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName
      var head = this.head
      if (head.length == 0) {
        let fieldNames = []
        let heads = []
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field)
            heads.push(this.myTableHeard[i].name)
          }
        }
        fieldName = fieldNames
        head = heads
      }
      //加密数组格式
      let signArr = [
        {
          key: 'fieldName',
          val: fieldName,
        },
        {
          key: 'head',
          val: head,
        },
      ]
      let sign = tools.getMD5Sign(signArr)
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      }
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || ''
        //下载(这里其实是直接访问)
        var a = document.createElement('a')
        a.setAttribute('href', downloadurl)
        a.setAttribute('target', '_blank')
        a.click()
      })
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this
      let datas = that.tableData
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      }
      let signarr = tools.getSignArr(parm)
      let signs_e = tools.getMD5Sign(signarr)

      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl
        var a = document.createElement('a')
        a.style.display = 'none'
        a.href = downloadurl
        a.target = '_blank'
        a.setAttribute('download', '综合查询.xlsx') //指定下载后的文件名，防跳转
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true
    },
    // 点击导出按钮
    exportBtn() {
      let that = this
      let fieldName = []
      let head = []
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field)
          head.push(this.myTableHeard[i].name)
        }
      }
      this.fieldName = fieldName
      this.head = head
      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId
          that.templateDataExport(customizedExportId)
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            '问题类型列表'
          )
        }
      })
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0
        resolve()
      })
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight()
        //console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout()
        })
      })
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight
      let queryHeigth
      let btnListHeight = this.$refs.btnList.offsetHeight + 16 // 按钮列表  16==上下的margin
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight //查询框
      } else {
        queryHeigth = 50
      }
      let pagsHeigth = 40 //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10 //60是顶部导航 ,10==预留空位
      return Math.floor(windonHeight - otherHeight) || '50vh' //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload() //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let formData = this.queryData
      let that = this
      formData.pageStart = that.pageNum
      formData.pageTotal = that.size
      Api.getCalentdarActionList(formData).then((res) => {
        //console.log(111,res.data.result.data)
        if (res.data.status == 'success') {
          that.className = res.data.result.className || ''
          that.tableData = res.data.result.data || []
          that.total = res.data.result.pageCount || 0
          this.fetTableHeight()
        }
      })
    },
    // 查询按钮
    queryBtn_ok() {
      // 别的参数暂时没有
      this.pageNum = 1
      this.getData()
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        // startCreateDate: '',
        // endCreateDate: '',
        calendarName: '',
        storageId: '',
        channelTransportId: '',
      }
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || []
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject)
            this.sortName = arr[0].sortName
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle
        }
        let totalArr = []
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field)
          }
        }
        that.totalArr = totalArr
      })

      that.$nextTick(() => {
        that.$refs.mytable.doLayout()
      })
    },
    // 设置模板按钮
    myModel() {
      let that = this
      that.show_sortTableHeard = true
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard))
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true
      } else {
        that.allCheck = false
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e
      this.myTableHeard = this.tabelHeadeTitle
      let sortObject = this.myTableHeard
      let signs = [
        {
          key: 'sortName',
          val: this.sortName, //模板名称
        },
        {
          key: 'groupName',
          val: this.pageGroupName, //页面标识
        },
        {
          key: 'sortObject',
          val: JSON.stringify(sortObject),
        },
      ]

      let sign = tools.getMD5Sign(signs)

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      }
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || '操作成功')
        this.getGroupFindFieldSort()
      })
      this.show_sortTableHeard = false //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
      this.show_sortTableHeard = false
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this
      that.tabelHeadeTitle[idx].isShow = e
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true
      } else {
        that.allCheck = false
      }
    },
    //全选
    checkAll(e) {
      let that = this
      this.allCheck = e
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e
      }
      let selectLength = 0
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++
        }
      }
      that.selectLength = selectLength
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e
    },
    // 合计
    getSummaries(param) {
      let that = this
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map((item) => Number(item[column.property]))
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2)
                } else {
                  return prev
                }
              }, 0)
              sums[index] += ''
            } else {
              sums[index] = '---'
            }
          }
        }
      })
      this.$nextTick(() => {
        this.$refs.mytable.doLayout()
      })
      return sums
    },
    //导出操作
    handleCommand_dc(command) {
      if (command == 'dc') {
        // 导出
        this.exportBtn()
      } else if (command == 'dcmb') {
        // 下载模板
        this.exportTemplateDownload()
      } else if (command == 'scmb') {
        // 上传模板
        this.upLoadBtn()
      }
    },
    //分页
    handleSizeChange(val) {
      this.size = val
      this.getData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getData()
    },
  },
}
</script>

<style scoped lang="scss">
.quill-editor /deep/ .ql-container {
  min-height: 220px;
}

.ql-container {
  min-height: 230px;
}
.tableBox {
  height: calc(100% - 260px);
}
.nodlist {
  display: flex;
  span {
    display: block;
    margin-left: 12px;
    cursor: pointer;
  }
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}
.newForm {
  width: 100%;
  display: flex;
}

.queryItem1 {
  width: 60%;
  margin: 0 10%;
  margin-top: 20px;
}

.queryItem2 {
  width: 100%;
}
.footbox {
  margin-top: 20px;
  width: 100%;
  justify-content: center;
}
.formbox {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
